import React from 'react'
import Heading from 'components/Heading'

import { gridContainer, loveSection, strategyIcon, contentIcon, creativeIcon, commsIcon, icon } from './love.module.css'

function Love() {
    return (
        <section className={loveSection}>
            <Heading level='h3'>What We Love Doing</Heading>
            <Heading level='h4'>We're a small agency with bold ideas. Ideas that, when implemented, turn into results. The closer we work with you, the better the outputs. The better the outcomes, the happier we all are. </Heading>
        
            <div className={gridContainer}>
                <div>
                    <div className={[strategyIcon, icon].join(' ')}>
                        <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.7664 23.944H18.5V26.1216H21.7664V23.944Z" fill="url(#paint0_linear)"/>
                            <path d="M27.2104 23.944H23.944V26.1216H27.2104V23.944Z" fill="url(#paint1_linear)"/>
                            <path d="M32.6448 23.944H29.3784V26.1216H32.6448V23.944Z" fill="url(#paint2_linear)"/>
                            <path d="M14.1448 13.056V10.8784H25.0232V2.1776H14.1448V0H11.9672V13.056H7.62161V8.71042H4.35521C1.95599 8.71042 0 10.6568 0 13.056V32.6448C0 35.044 1.95599 37 4.35521 37H37V13.056H14.1448ZM22.8552 4.35521V8.71042H14.1448V4.35521H22.8552ZM2.1776 13.056C2.1776 11.8516 3.15078 10.8784 4.35521 10.8784H5.44401V28.2896C4.35521 28.2607 3.13151 28.2703 2.1776 28.8773V13.056ZM34.8224 34.8224H4.35521C1.46458 34.7068 1.47422 30.5924 4.35521 30.4672H7.62161V15.2336H11.9768V21.9495C8.56589 23.2117 9.3849 28.2221 13.0656 28.2896C16.7464 28.2221 17.5654 23.2117 14.1544 21.9495V15.2336H34.832V34.8224H34.8224ZM14.1448 25.0328C14.1448 25.6302 13.6534 26.1216 13.056 26.1216C11.6107 26.0638 11.6107 24.0018 13.056 23.944C13.663 23.944 14.1448 24.4258 14.1448 25.0328Z" fill="url(#paint3_linear)"/>
                            <defs>
                            <linearGradient id="paint0_linear" x1="20.1332" y1="23.944" x2="20.1332" y2="26.1216" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#E63712"/>
                            <stop offset="1" stopColor="#C53818"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear" x1="25.5772" y1="23.944" x2="25.5772" y2="26.1216" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#E63712"/>
                            <stop offset="1" stopColor="#C53818"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear" x1="31.0116" y1="23.944" x2="31.0116" y2="26.1216" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#E63712"/>
                            <stop offset="1" stopColor="#C53818"/>
                            </linearGradient>
                            <linearGradient id="paint3_linear" x1="18.5" y1="0" x2="18.5" y2="37" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#E63712"/>
                            <stop offset="1" stopColor="#C53818"/>
                            </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    
                    <Heading level='h4'>Strategy</Heading>
                    <p>We roll ourselves up, delve into your offering and start to dig the good dirt on how to market your business. We'll set objectives, test your messages with your customers, agree on a plan of action and change how you market yourself. Promise.</p>
                </div>

                <div>
                    <div className={[contentIcon, icon].join(' ')}>
                        <svg  width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.2977 29.1156H29.1242V23.2891H23.2977V29.1156ZM25.2312 25.2312H27.1734V27.1734H25.2312V25.2312Z" fill="white"/>
                            <path d="M15.5289 19.4133H9.70235V25.2398H15.5289V19.4133ZM13.5867 23.2976H11.6445V21.3555H13.5867V23.2976Z" fill="white"/>
                            <path d="M28.1445 19.4133H17.4711V21.3555H28.1445V19.4133Z" fill="white"/>
                            <path d="M21.3555 23.2977H17.4711V25.2399H21.3555V23.2977Z" fill="white"/>
                            <path d="M21.3469 27.1734H9.70235V29.1156H21.3469V27.1734Z" fill="white"/>
                            <path d="M5.82656 0V18.4422H0V29.1156C0 31.2555 1.74453 33 3.88438 33H29.1156C31.2555 33 33 31.2555 33 29.1156V0H5.82656ZM31.0578 1.94219V15.5289H7.76875V1.94219H31.0578ZM1.94219 29.1156V20.3844H5.82656V29.1156C5.71484 31.6937 2.04531 31.6937 1.94219 29.1156ZM29.1156 31.0578H7.24453C7.58828 30.4734 7.76875 29.7945 7.76875 29.1156V17.4711H31.0664V29.1156C31.0578 30.1898 30.1898 31.0578 29.1156 31.0578Z" fill="white"/>
                            <path d="M29.1156 3.88437H9.70235V13.5867H29.1156V3.88437ZM27.1734 11.6445H11.6445V5.82656H27.1734V11.6445Z" fill="white"/>
                            <path d="M25.2312 7.76874H13.5867V9.71093H25.2312V7.76874Z" fill="white"/>
                        </svg>
                    </div>

                    <Heading level='h4'>Content</Heading>
                    <p>Someone once said, "I can't write like this, how do you do it?". Another, "reading that almost brought a tear to my eye". Finally, "honestly, that content you did for me was superb". References available upon request. As is content writing.</p>
                </div>

                <div>
                    <div className={[creativeIcon, icon].join(' ')}>
                        <svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.6569 0H17.3431V3.53125H19.6569V0Z" fill="#1A1A1A"/>
                            <path d="M4.59685 6.34375L6.23492 4.67708L8.69203 7.17708L7.05396 8.84375L4.59685 6.34375Z" fill="#1A1A1A"/>
                            <path d="M3.47067 17.6458H0V20H3.47067V17.6458Z" fill="#1A1A1A"/>
                            <path d="M4.59685 31.3021L7.05396 28.8021L8.69203 30.4687L6.23492 32.9688L4.59685 31.3021Z" fill="#1A1A1A"/>
                            <path d="M28.308 30.4687L29.946 28.8021L32.4032 31.3021L30.7651 32.9688L28.308 30.4687Z" fill="#1A1A1A"/>
                            <path d="M37 17.6458H33.5293V20H37V17.6458Z" fill="#1A1A1A"/>
                            <path d="M28.308 7.17708L30.7651 4.67708L32.4032 6.34375L29.946 8.84375L28.308 7.17708Z" fill="#1A1A1A"/>
                            <path d="M25.4209 29.6875C35.9046 22.6146 31.2258 6.17708 18.6638 5.88542C6.0097 5.90625 0.952144 22.4167 11.5177 29.6458C12.992 30.6354 13.8827 32.2812 13.8827 34.0625V35.3021H11.5689V37.6562H13.8827V40H23.1378V37.6458H25.4516V35.2917H23.1378V34.0521C23.1276 32.2708 23.9876 30.6354 25.4209 29.6875ZM12.7872 27.6771C7.30992 24.125 6.48064 15.8958 11.2106 11.3229C13.2172 9.30208 15.8586 8.20833 18.6331 8.23958C28.8813 8.45833 32.741 21.9896 24.1616 27.7083C22.37 28.8958 21.1926 30.7917 20.8957 32.9375H16.1146C15.7972 30.8125 14.6096 28.8958 12.7872 27.6771ZM16.1862 37.6458V35.2917H20.8138V37.6458H16.1862Z" fill="#1A1A1A"/>
                        </svg>
                    </div>
                    
                    <Heading level='h4'>Creative</Heading>
                    <p>Bored of that Arial font you're using? Tired of the same A4 pamphlet for your sales pitches? Sick of the competition getting more business just because they look better? We'll turn design humdrum into creative yum yum.</p>
                </div>

                <div>
                    <div className={[commsIcon, icon].join(' ')}>
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.3562 15.8812C26.6906 15.8812 28.5937 13.9781 28.5937 11.6437C28.3594 6.02812 20.3531 6.02812 20.1187 11.6437C20.1187 13.9781 22.0219 15.8812 24.3562 15.8812ZM24.3562 9.525C25.5281 9.525 26.475 10.4719 26.475 11.6437C26.3625 14.4562 22.3594 14.4562 22.2375 11.6437C22.2375 10.4812 23.1844 9.525 24.3562 9.525Z" fill="white"/>
                            <path d="M34.9403 0C29.6153 0 24.7216 1.275 20.3903 3.79688C19.2184 4.48125 18.1497 5.2125 17.1841 5.9625L13.0966 5.15625C9.9747 4.54688 6.76845 5.52188 4.51845 7.77188L0.0371954 12.2625L8.53095 14.3531C7.79032 15.075 7.11532 15.75 6.66532 16.1813L5.89657 16.9313L8.0247 19.0594L4.84657 22.2375L6.15907 23.55L4.5372 27.5438C2.0997 27.3563 -0.00967956 29.325 -0.000304562 31.7625V36H4.2372C6.6747 36.0094 8.65282 33.8906 8.45595 31.4625L12.4497 29.8406L13.7622 31.1531L16.9403 27.975L19.0684 30.0938L19.8184 29.3344C20.2497 28.8938 20.9341 28.2094 21.6653 27.4688L23.7841 35.9532L28.2559 31.4813C30.5153 29.2219 31.4903 26.0063 30.8622 22.875L30.0466 18.7875C33.9372 13.7438 36.0278 7.61251 36.0091 1.05938V0H34.9403ZM4.19032 11.1L6.01845 9.26251C7.77157 7.50938 10.2653 6.75 12.6934 7.22813L15.1122 7.70626C12.8434 9.81563 12.6091 10.3688 10.2934 12.6L4.19032 11.1ZM4.2372 33.8813H2.11845V31.7625C2.11845 30.5906 3.06532 29.6438 4.2372 29.6438C7.04032 29.7656 7.04032 33.7688 4.2372 33.8813ZM7.78095 29.4469C7.4622 28.9594 7.04032 28.5375 6.55282 28.2188L7.79032 25.1719L10.8278 28.2094L7.78095 29.4469ZM13.7622 28.1531L7.84657 22.2375L9.5247 20.5594L15.4403 26.475L13.7622 28.1531ZM28.7809 23.2875C29.2684 25.725 28.5091 28.2281 26.7559 29.9813L24.9278 31.8094L23.3997 25.7063C24.1966 24.9094 24.8434 24.2625 25.0591 24.075C25.9872 23.25 27.1216 22.1906 28.2934 20.8781L28.7809 23.2875ZM23.6434 22.5C23.1091 22.9781 20.3903 25.7156 19.0497 27.0844L8.91532 16.95C10.3684 15.525 13.3122 12.6281 13.7997 12.0844C17.4934 7.95001 23.5497 2.49375 33.8622 2.1375C33.4966 12.5063 27.8997 18.7125 23.6434 22.5Z" fill="white"/>
                            <path d="M14.0719 15.5719L15.5719 14.0719L21.9281 20.4281L20.4281 21.9281L14.0719 15.5719Z" fill="white"/>
                        </svg>
                    </div>

                    <Heading level='h4'>Comms</Heading>
                    <p>Linking the strategy, content and creative together, we're dab hands at getting your message out to the right audiences. Whether that's through earned media, outbound marketing, or paid, we can do it for you (or know someone who can).</p>
                </div>
                
            </div>
        </section>
    )
}

export default Love