import * as React from "react"
import { graphql } from "gatsby"

import Hero from "components/Hero"
import Layout from "components/layout"
import MarketingServices from "components/MarketingServices"
import Love from "components/Love"
import Process from "components/Process"
import We from "components/We"
import Seo from "components/seo"

// markup
const IndexPage = ({ data }) => {
  const { wpPage: page } = data;

  return (
    <Layout>
      <Seo 
        title={ page.seo.title }
        description={ page.seo.description }
      />
      <Hero 
        title="B2B Marketing That Just Sticks"
        subtitle="We’re an agency that loves the power of promoting professional services. But why? "
      />
      <MarketingServices />
      <Love/>
      <Process/>
      <We />
    </Layout>
  );
}

export const query = graphql`
  query IndexPageQuery {
    wpPage( title: { eq: "Index"}){
      seo {
        title
        metaDesc
      }
    }
  }
`

export default IndexPage
