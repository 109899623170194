import React from 'react'
import Heading from 'components/Heading'

import {marketingServicesSection, content, numberList, numberedItem} from './marketingServices.module.css'

function MarketingServices() {
  return (
    <section className={marketingServicesSection}>
      <div className={content}>
        <Heading level="h3">Just because they can't SEE it, doesn't mean we can't SELL it</Heading>
        <p>Accountants. Financial services. HR providers. Recruitment companies. Web developers. Not necessarily the companies most young marketers dream about marketing. But we do. </p>
        <p>Because there's something special that lies in marketing services, you can't physically pick them up, so you need to work smarter - more strategic, if you will. </p>
        <p>And, trust us (or trust Adam), far too many businesses that offer services get their marketing "slightly" wrong. We don't do wrong. We do you right, by:</p>
      </div>
    
      <div className={numberList}>
        <div className={numberedItem}>
          <Heading level="h4">1</Heading>
          <Heading level="h4">Focusing on what your clients want to hear rather than what you think they do</Heading>
        </div>

        <div className={numberedItem}>
          <Heading level="h4">2</Heading>
          <Heading level="h4">Ditching the sausage and selling the sizzle</Heading>
        </div>

        <div className={numberedItem}>
          <Heading level="h4">3</Heading>
          <Heading level="h4">Making you uncomfortable. If you're not squirming, we're not doing our job </Heading>
        </div> 
      </div>
    </section>
  );
}

export default MarketingServices