import React from 'react'
import Heading from 'components/Heading'

import {weSection} from './we.module.css'

function Process() {
    return (
        <section className={weSection}>
            <div>
                <Heading level='h4'>We Connect</Heading>
                <p>Talking means we understand your challenges, goals and aspirations. Connecting means we start formulating a plan that's right for you and your business.</p>
            </div>

            <div>
                <Heading level='h4'>We Research</Heading>
                <p>You got data? We'll analyse it. You got competitors? We'll check them out. You want client feedback? We'll get it. These insights form the basis of how we promote you.</p>
            </div>

            <div>
                <Heading level='h4'>We Execute</Heading>
                <p>We know what you're thinking, but this execution is exciting, not deathly. It's when all of the planning and research comes together and is showcased to your targets.</p>
            </div>
            
        </section>
    )
}

export default Process